import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import s from './styles.module.scss';
import data from '../../json/data.json';
import emailjs from '@emailjs/browser';

const { shortName, longName, invalidName, requiredName, invalidEmail, requiredEmail, invalidNumber, requiredNumber } = data.form.validation


const onSubmitHandle = (values, { setSubmitting, resetForm }) => {
    try {
        emailjs.send('service_e4jnksu', 'template_kgfrgf2', values, 'QM4EXjC-HuhJKyZn-')
            .then(() => {
                //sentMessage.classList.add('success');
                //sentMessage.innerHTML = CONTACT_ERROR.success;
                //setButtonState('Send Email');
                setSubmitting(false);
                resetForm();
            });
    }
    catch {
        // sentMessage.classList.add('error');
        // sentMessage.innerHTML = CONTACT_ERROR.error;
        // setButtonState('Send Email');
        setSubmitting(false);
    }
}

const onSubmitHandleTest = (values, { setSubmitting, resetForm }) => {
    console.log("values", values)
    // e.preventDefault();
    emailjs.send('service_e4jnksu', 'template_kgfrgf2', values, 'QM4EXjC-HuhJKyZn-');
}

const onSubmit = (values, { setSubmitting, resetForm }) => {
    //   setTimeout(() => {
    //     alert(JSON.stringify(values, null, 2));
    //     setSubmitting(false);
    //     resetForm();
    //   }, 1000);
    console.log(values);
    // emailjs.send(process.env.REACT_APP_FORMIK_SERVICE_ID, process.env.REACT_APP_FORMIK_TEMPLATE_ID,
    //     values, process.env.REACT_APP_FORMIK_USER_ID)
    //     .then(() => {
    //         console.log('email sent');
    //     });
};

class Forms extends Component {
    render() {
        //console.log(this.props.t('form.validation.shortName'))
        let shortName = this.props.t('form.validation.shortName')
        let longName = this.props.t('form.validation.longName')
        let invalidName = this.props.t('form.validation.invalidName')
        let requiredName = this.props.t('form.validation.requiredName')
        let invalidEmail = this.props.t('form.validation.invalidEmail')
        let requiredEmail = this.props.t('form.validation.requiredEmail')
        let requiredNumber = this.props.t('form.validation.requiredNumber')
        const SignupSchema = yup.object().shape({
            name: yup.string()
                .min(2, shortName)
                .max(50, longName)
                .matches(/[a-zA-zа-яА-яёЁ ]$/, invalidName)
                .matches(/[^\s]/, requiredName)
                .required(requiredName),
            email: yup.string()
                .email(invalidEmail)
                .required(requiredEmail),
            phoneNumber: yup.string()
                //.matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, invalidNumber)
                .required(requiredNumber),
            message: yup.string()
        });
        return (
            <div className={`${s.form}
        ${this.props.className}
        ${this.props.adaptive && s.adaptive}
        ${this.props.white && s.white_style}
        `}>
                <h3 className={s.title}>
                    {this.props.t('form.title')}
                </h3>
                <span className={s.subtitle}>
                    {this.props.t('form.subtitle')}
                </span>
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        phoneNumber: '',
                        message: '',
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={onSubmitHandleTest}
                >
                    {({ errors, touched }) => (
                        <Form className={s.formik}>
                            <div className={s.formik_item_wrapper}>
                                <Field placeholder={this.props.t('form.name')} name="name" className={s.formik_item} />
                                {errors.name && touched.name ? (
                                    <div className={s.invalid}>
                                        {errors.name}
                                    </div>
                                ) : null}
                            </div>
                            <div className={s.formik_item_wrapper}>
                                <Field placeholder={this.props.t('form.email')} name="email" type="email" className={s.formik_item} />
                                {errors.email && touched.email ? (
                                    <div className={s.invalid}>
                                        {errors.email}
                                    </div>
                                ) : null}
                            </div>
                            <div className={s.formik_item_wrapper}>
                                <Field placeholder={this.props.t('form.number')} name="phoneNumber" type="tel" className={s.formik_item} />
                                {errors.phoneNumber && touched.phoneNumber ? (
                                    <div className={s.invalid}>
                                        {errors.phoneNumber}
                                    </div>
                                ) : null}
                            </div>
                            <div className={s.formik_item_wrapper}>
                                <Field placeholder={this.props.t('form.message')} type="text" as="textarea" name="message" className={`${s.formik_item} ${s.textarea}`} />
                                {errors.message && touched.message ? (
                                    <div className={s.invalid}>
                                        {errors.message}
                                    </div>
                                ) : null}
                            </div>
                            <button type="submit" className={s.btn}>{this.props.t('form.submit')}</button>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

export default withTranslation()(Forms);