import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Slider from "react-slick";
import data from '../../json/data.json'
import Slide from "./slide";
// styles
import s from './styles.module.scss'
// images
import left from './img/left.svg'
import right from './img/right.svg'
import auto from './img/auto.png'
import auto2 from './img/auto02.png'
import auto3 from './img/auto03.png'


class Reviews extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    isNeedZero(num) {
        return num < 10 ? '0' + num : num
    }
    state = {
        activeSlide: 0
    };
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: false,
            autoplaySpeed: 4000,
            cssEase: "linear",
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
            beforeChange: (current, next) => this.setState({ activeSlide: next }),
            responsive: [
                {
                    breakpoint: 990,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                }
            ]
        };
        const review_link = data.navigation[4][1]
        const { bg_title, reviews, users, lookReviews } = data.reviews
        return (
            <section className={s.reviews} id={review_link.slice(3)}>
                <div className={s.container}>
                    <div className={s.reviews_main}>
                        <span className={s.reviews_bg_title}>
                            {this.props.t('reviews.bg_title')}
                        </span>
                        <div className={s.slider_container}>
                            <div className={s.slider_top}>
                                <h3 className={s.title}>
                                    <span className={s.bold}>{this.props.t('reviews.reviews').split(' ')[0] + ' '}</span>
                                    {this.props.t('reviews.reviews').split(' ').slice(1).join('')}
                                </h3>
                                <div className={s.slider_nav}>
                                    <button onClick={this.previous} className={s.left}>
                                        <img src={left} className={s.arrow} alt="left"></img>
                                    </button>
                                    <div className={s.count}>
                                        <span className={s.current}>
                                            {this.isNeedZero(this.state.activeSlide + 1)}
                                        </span>
                                        /
                                        <span className={s.total}>
                                            {this.isNeedZero(this.props.t('reviews.users', { returnObjects: true }).length)}
                                        </span>
                                    </div>
                                    <button onClick={this.next} className={s.right}>
                                        <img src={right} className={s.arrow} alt="right"></img>
                                    </button>
                                </div>
                            </div>
                            <div className={s.slider}>
                                <Slider ref={c => (this.slider = c)} {...settings}>
                                    {this.props.t('reviews.users', { returnObjects: true }).map((data, i) => <Slide data={data} key={i}></Slide>)}
                                </Slider>
                            </div>
                            <div className={s.btn_container}>
                                <a
                                    href="https://www.google.com/maps/place/Yauhen+Ihin+Auction+House/@53.533281,17.518769,15z/data=!4m6!3m5!1s0x47024d3b34902697:0x18ab5404ec7b97b7!8m2!3d53.533281!4d17.518769!16s%2Fg%2F11kqgg_kvc?hl=ru&entry=ttu"
                                    target="_blank" className={s.btn} rel="noreferrer">
                                    {this.props.t('reviews.lookReviews')}
                                </a>
                            </div>
                        </div>
                    </div>
                    <img className={s.auto} src={auto3} alt="Auto" />
                </div>
            </section>
        )
    }
}

export default withTranslation()(Reviews);
