import React, { Component } from "react";

export default class AdvantagesItem extends Component {
    render() {
        const { text, img, altImg } = this.props;
        return (
            <li>
                <figure>
                    <img src={img} alt={altImg} />
                </figure>
                <p>
                    {text}
                </p>
            </li>
        )
    }
}