import React, { Component } from "react";
import Slider from "react-slick";
import Slide from "./CarSlide";
import data from '../../json/data.json'
import s from './styles.module.scss'

export default class Responsive extends Component {
    render() {
        const { cards } = data.ourWorks;

        const GalleryPrevArrow = ({ currentSlide, slideCount, ...props }) => {
            const { className, onClick } = props;

            return (
                <div {...props} className={s.customPrevArrow} onClick={onClick}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                    </svg>
                </div>
            );
        };
        const GalleryNextArrow = ({ currentSlide, slideCount, ...props }) => {
            const { className, onClick } = props;

            return (
                <div {...props} className={s.customNextArrow} onClick={onClick}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
                    </svg>
                </div>
            );
        };

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            nextArrow: <GalleryNextArrow />,
            prevArrow: <GalleryPrevArrow />,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div>

                <Slider {...settings} className={s.slickSlider}>
                    {cards.map((data, i) => <Slide liClass={s.advantages_item} card={data} key={i}></Slide>)}
                </Slider>
            </div>
        );
    }
}