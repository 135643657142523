import React from "react";
import { useTranslation } from 'react-i18next';
import 'reactjs-popup/dist/index.css';
// styles
import s from './styles.module.scss'
import data from '../../json/data.json'

export const Services = () => {
    const { t } = useTranslation()

        const service_link = data.navigation[0][1]
        return (
            <section className={s.services} id={service_link.slice(3)}>
                <div className={s.container}>
                    <div className={s.services__main}>
                        <div className={s.services__left}>
                            <h3>{t('services.services')}</h3>
                        </div>
                    </div>
                    <div className={s.description}>
                        <div className={s.reg_p}>
                            {t('services.consultation')}
                        </div>
                        <div className={s.reg_p}>
                            {t('services.predesc0')}
                        </div>
                        <div className={s.reg_p}>
                            {t('services.predesc')}
                        </div>
                        <p className={s.reg_p}>
                            {t('services.description')}
                        </p>
                        <p className={s.reg_p}>
                            {t('services.text_p1')}
                        </p>
                        <p className={s.reg_p}>
                            {t('services.text_p2')}
                        </p>
                        <p className={s.reg_p}>
                            {t('services.text_p3')}
                        </p>
                    </div>
                </div>
            </section>
        )
}
