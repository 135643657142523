import React from "react";
import { useTranslation } from 'react-i18next';
import data from '../../json/data.json'
import AdvantagesItem from "./AdvantagesItem";
// styles
import s from './styles.module.scss'
// images
import handshake from './img/handshake.png'
import agreement from './img/agreement.png'
import speed from './img/speed.png'
import airbag from './img/airbag.png'
import goodjob from './img/good-job.png'
import car8 from './img/car08.png'




export const Advantages = () => {
    const { t } = useTranslation()
        const { advantages, subtitle, text_item1, text_item2, text_item3, text_item4, text_item5 } = data.advantages
        const { logo } = data.header
        const advantages_link = data.navigation[1][1]
        return (
            <section className={s.advantages} id={advantages_link.slice(3)}>
                <div className={s.container}>
                    <h3>
                        <i></i>
                        <p>{t('advantages.advantages')}</p><span>{t('header.logo')}</span>
                    </h3>
                    <p className={s.title_p}>{t('advantages.subtitle')}</p>
                    <div className={s.advantages__main}>
                        <div className={s.advantages__us_left}>
                            <img src={car8} alt="Auto" />
                        </div>
                        <div className={s.advantages__us_right}>
                            <div className={s.advantages_us__cont}>
                                <span className={s.rotate_span}>{t('advantages.advantages')}</span>
                                <ul>
                                    <AdvantagesItem text={t('advantages.text_item1')} img={handshake} altImg="Handshake"></AdvantagesItem>
                                    <AdvantagesItem text={t('advantages.text_item2')} img={agreement} altImg="agreement"></AdvantagesItem>
                                    <AdvantagesItem text={t('advantages.text_item3')} img={speed} altImg="speed"></AdvantagesItem>
                                    <AdvantagesItem text={t('advantages.text_item4')} img={airbag} altImg="airbag"></AdvantagesItem>
                                    <AdvantagesItem text={t('advantages.text_item5')} img={goodjob} altImg="goodjob"></AdvantagesItem>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )

}
