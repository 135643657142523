import React from "react";
import { useTranslation } from 'react-i18next';
import data from '../../json/data.json'
// styles
import s from './styles.module.scss'
import auto from './auto.png'
const CountingPrice = () => {

    const { t } = useTranslation();
    const renderListLi = ($class, data) => {
        return data.map((text, i) => {
            return (
                <li className={`${$class} ${s.sq_list_style}`} key={i}>
                    {text}
                </li>
            )
        })
    }

        const price_link = data.navigation[3][1]
        const { countingPrice, btns, footnotes, packages, costList, costCarTitle, priceCost, costList2, costCarTitle2, priceCost2, costList3, costCarTitle3, priceCost3, costCarTitle4, priceCost4, description, subtitle } = data.countingPrice
        return (
            <section className={s.price} id={price_link.slice(3)}>
                <div className={s.container}>
                    <h3 className={s.title}>
                        {t('countingPrice.countingPrice')}
                    </h3>
                    {/* <span className={s.subtitle}>{subtitle}</span> */}
                    <div className={s.price__main}>
                        <div className={s.price_left}>
                            {/* <p className={s.description}>
                                {description}
                            </p> */}
                            <span className={s.cost_car_title}> {t('countingPrice.costCarTitle')}</span>
                            <div className={s.subtitle}>{t('countingPrice.priceCost')}</div>
                            <ul className={s.cost_car_list}>
                                {renderListLi(s.cost_car_item, t('countingPrice.costList', { returnObjects: true }))}
                            </ul>
                            <span className={s.cost_car_title}>{t('countingPrice.costCarTitle2')}</span>
                            <div className={s.subtitle}>{t('countingPrice.priceCost2')}</div>
                            <ul className={s.cost_car_list}>
                                {renderListLi(s.cost_car_item, t('countingPrice.costList2', { returnObjects: true }))}
                            </ul>
                            {/* <div className={s.auto_container}>
                                <img className={s.auto} src={auto} alt='Auto' />
                            </div> */}

                        </div>
                        <div className={s.price_right}>
                            <span className={s.cost_car_title}>{t('countingPrice.costCarTitle3')}</span>
                            <div className={s.subtitle}>{t('countingPrice.priceCost3')}</div>
                            <ul className={s.cost_car_list}>
                                {renderListLi(s.cost_car_item, t('countingPrice.costList3', { returnObjects: true }))}
                            </ul>
                            <p>{t('countingPrice.costAdd3')}</p>
                            <span className={s.cost_car_title}>{t('countingPrice.costCarTitle4')}</span>
                            <div className={s.subtitle}>{t('countingPrice.priceCost4')}</div>

                            {/* <PriceItem data={packages[0]}></PriceItem>
                            <PriceItem data={packages[1]}></PriceItem>
                            <PriceItem data={packages[2]}></PriceItem> */}
                        </div>
                    </div>
                    {/* <div className={s.buttons}>
                        <a className={s.btn} href={btns[0].link}>{btns[0].name}</a>
                        <a className={s.btn} href={btns[1].link}>{btns[1].name}</a>
                    </div> */}
                    <ul className={s.footnotes_list}>
                        {renderListLi(s.footnotes_item, t('countingPrice.footnotes', { returnObjects: true }))}
                    </ul>
                </div>
            </section>
        )
}

export default CountingPrice;
