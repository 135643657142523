import ald from './img/ald-automotive.jpg'
import alphabet from './img/alphabet.jpg'
import arval from './img/arval.jpg'
import athlon from './img/athlon-car-lease.jpg'
import autobid from './img/autobid.de.jpg'
import autorola from './img/autorola.jpg'
import bca from './img/bca-group.jpg'
import capital from './img/ge-capital.jpg'
import kbc from './img/kbc-autolease.jpg'
import leaseplan from './img/leaseplan.jpg'
import iaai from './img/iaai.png'
import copart from './img/copart.png'

const logotypes = [
    [ald, 'ALD Automotive'],
    [alphabet, 'Alphabet'],
    [arval, 'Arval (BNP Paribas Group)'],
    [athlon, 'Athlon Car Lease'],
    [autobid, 'Autobid.de'],
    [autorola, 'Autorola'],
    [bca, 'Bca Group'],
    [capital, 'Ge Capital'],
    [kbc, 'Kbc Autolease'],
    [leaseplan, 'LeasePlan Auction'],
    [iaai, 'Iaai Auction'],
    [copart, 'Copart Auction']
]

export default logotypes