import React, { Component } from "react";

export default class AdvantagesItem extends Component {
    render() {
        const { liClass, text} = this.props;
        return (
            <li className={liClass}>
                    <span>{text}</span>
            </li>
        )
    }
}