import React, { Component } from "react";
import data from '../../json/data.json'
// styles
import s from './styles.module.scss'

export default class Questions extends Component {
    renderFAQ(data) {
        return data.map((questAnswer, i) => {
            return (
                <div className={s.quest} key={i}>
                    <input type="checkbox" id={`accordion-${i}`} name="accordion-checkbox" />
                    <label for={`accordion-${i}`}>{questAnswer[0]}
                        <p className={s.accordion_content}>
                            {questAnswer[1]}
                        </p>
                    </label>
                </div>
            )
        })
    }

    render() {
        const { questions, subtitle, faq } = data.questions
        return (
            <section className={s.questions}>
                <div className={s.container}>
                    <h3 className={s.title}>
                        {questions.split(' ').slice(0, -2).join(' ') + ' '}
                        <span className={s.bold}>{questions.split(' ').slice(-2).join(' ')}</span>
                    </h3>
                    <p className={s.subtitle}>{subtitle}</p>
                    <div className={s.accordion}>
                        {this.renderFAQ(faq)}
                    </div>
                </div>
            </section>
        )
    }
}
