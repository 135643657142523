import React from "react";
import { useTranslation } from 'react-i18next';
import data from '../../json/data.json'
import logotypes from "./logotypes";
// styles
import s from './styles.module.scss'
// images
import ex from './img/ex.svg'


const Auctions = () => {
    const { t } = useTranslation();
    const renderLogotypes = (data, $class) => {
        return data.map((logo, i) => {
            return (
                <img className={$class} src={logo[0]} alt={logo[1]} key={i} />
            )
        })
    }

        // const { auctions } = data.auctions
        return (
            <section className={s.auctions} id="partners">
                <div className={s.container}>
                    <h3 className={s.title}>
                        <span className={s.bold}>{t('auctions.auctions').split(' ').slice(0, -1).join(' ') + ' '}
                        </span>
                        <span className={s.orangeBold}>{t('auctions.auctions').split(' ').at(-1)}</span>
                    </h3>
                    <div className={s.logotypes}>
                        {renderLogotypes(logotypes, s.logo)}
                    </div>
                </div>
            </section>
        )
}

export default Auctions;
