import React, { Component } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Forms from '../form/forms'
import data from '../../json/data.json'
// styles
import s from './styles.module.scss'



export default class Consultation extends Component {

    render() {
        const { questions_title, text, btn_text, btn_link } = data.consultation
        return (
            <section className={s.consultation}>
                <div className={s.container}>
                    <div className={s.text}>
                        <span className={s.title}>{questions_title}</span>
                        <p className={s.text}>{text}</p>
                    </div>
                    <div className={s.btn}>
                        <Popup lockScroll modal trigger={
                            <a className={s.btn_link} href={btn_link} >{btn_text}</a>
                        }>
                            {close => (
                                <>
                                    <div className="close" onClick={close}>
                                        &times;
                                    </div>
                                    <Forms adaptive white>
                                    </Forms>
                                </>
                            )}
                        </Popup>
                    </div>
                </div>
            </section>
        )
    }
}
