import React from "react";
import Popup from 'reactjs-popup';
import { useTranslation } from 'react-i18next';
import 'reactjs-popup/dist/index.css';
import Forms from '../form/forms'
// styles
import s from './styles.module.scss'

export const MainBlock = () => {
    const { t } = useTranslation();
    return (
        <section className={s.main_block}>
            <div className={s.container}>
                <div className={s.main_block__left}>
                    <p className={s.title_p}>
                        {t('main_block.representative')}
                    </p>
                    <h1>
                        {t('main_block.title')}
                    </h1>
                    <Popup lockScroll modal trigger={
                        <a href="##" className={s.consult}>
                            {t('main_block.consultation')}
                        </a>
                    } position="right center">
                        {close => (
                            <>
                                <div className="close" onClick={close}>
                                    &times;
                                </div>
                                <Forms adaptive white>
                                </Forms>
                            </>
                        )}
                    </Popup>
                </div>
            </div>
        </section>
    )

}
