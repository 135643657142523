import React, { Component } from "react";
import data from '../../json/data.json'
// styles
import s from './styles.module.scss'

export default class Banner extends Component {


    render() {
        const { text, social, url } = data.banner
        return (
            <section className={s.banner}>
                <div className={s.container}>
                    <h3 className={s.title}>{text + ' '}
                        <a className={s.link} href={url} target="_blank" rel="noreferrer">{social}</a>
                    </h3>{' '}
                </div>
            </section>
        )
    }
}
