import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import data from '../../json/data.json'
// styles
import s from './styles.module.scss'

class Footer extends Component {
    renderListLi($classLi, $classLink, data, isLink = true, separation = '') {
        return data.map((arrTextUrl, i) => {
            const [label, url] = arrTextUrl;
            const inner = isLink ? (<a className={$classLink} href={url}>{label}</a>) : arrTextUrl + separation
            return (
                <li className={$classLi} key={i}>
                    {inner}
                </li>
            )
        })
    }
    render() {
        const { subtitle, developer, madeBy, copyright, title_models, popular_models, title_leasing, leasing, title_options, options } = data.footer
        const { logo } = data.header
        const { navigation } = data
        let today = new Date();
        let year = today.getFullYear();
        return (
            <footer className={s.footer}>
                <div className={s.footer_top}>
                    <div className={s.container}>
                        <div className={s.logo_container}>
                            <a href="/" className={s.logo}>
                                {this.props.t('header.logo')}
                            </a>
                            {/* <span className={s.subtitle}>
                                {subtitle}
                            </span> */}
                        </div>
                        <ul className={s.nav}>
                            {this.renderListLi(s.nav_item, s.nav_link, this.props.t('navigation', { returnObjects: true }))}
                        </ul>
                    </div>
                </div>
                <div className={s.footer_bottom}>
                    <div className={s.container}>

                        <div className={s.copyright}>
                            {/* <span className={s.developer}>
                                {madeBy + ' '}
                                <a className={s.link_dev} href={developer[1]}>{developer[0]}</a>
                            </span> */}
                            <span className={s.copyright_text}>
                                {this.props.t('footer.copyright')} - {year}
                            </span>
                        </div>
                        {/* <div className={s.description}>
                            <div className={s.left_descr}>
                                <span className={s.title_models}>{title_models}</span>
                                <ul className={s.models_list}>
                                    {this.renderListLi(s.description_item, '', popular_models, false, '; ')}
                                </ul>
                                <span className={s.title_leasing}>{title_leasing}</span>
                                <ul className={s.leasing_list}>
                                    {this.renderListLi(s.description_item, '', leasing, false, '; ')}
                                </ul>
                            </div>
                            <div className={s.right_descr}>
                                <span className={s.title_options}>{title_options}</span>
                                <ul className={s.options_list}>
                                    {this.renderListLi(s.description_item, '', options, false, '; ')}
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </footer>
        )
    }
}

export default withTranslation()(Footer);
