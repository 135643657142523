import React from 'react';
import { useTranslation } from 'react-i18next';

// styles
import s from '../styles.module.scss'

export const LANGUAGES = [
    { label: "PL", code: "pl" },
    { label: "EN", code: "en" },
    { label: "BY", code: "by" },
    { label: "DE", code: "de" }
];

const isActive = ({ isActive }) => `link ${isActive ? "active" : ""}`;

export const Menu = () => {
    const { i18n, t } = useTranslation()

    const onChangeLang = (e) => {
        const lang_code = e.target.value
        i18n.changeLanguage(lang_code)

    }
    return (
        <nav>
            <select defaultValue={i18n.language} onChange={onChangeLang} className={s.select} >
                {
                    LANGUAGES.map(({ code, label }) => (
                        <option
                            key={code}
                            value={code}
                            className={s.option_custom}
                        >{label}</option>
                    ))
                }
            </select>
        </nav>
    );
};