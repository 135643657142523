import React, { Component } from "react";
import { useTranslation } from 'react-i18next';
import data from '../../json/data.json'
import Forms from "../form/forms";
import MapComponent from "./MapComponent";
// styles
import s from './styles.module.scss'
// images
import tg from './img/tg.png'
import vb from './img/vb.png'
import wp from './img/wp.png'

const Contacts = () => {

    const { t } = useTranslation();
    const renderList = ($class, list) => {
        return list.map((item, i) => {
            return (
                <li key={i} className={$class}>
                    {item}
                </li>
            )
        })
    }
    const renderContactList = ($class, $classLink, list, prefix = '', social = false) => {
        return list.map((item, i) => {
            return (
                <li key={i} className={$class}>
                    <a className={$classLink} href={prefix + item}>
                        {item}
                    </a>
                    {social ? (
                        <div className={s.social}>
                            <img className={s.social_icon} src={vb} alt="Viber" />
                            <img className={s.social_icon} src={wp} alt="WhatsApp" />
                            <img className={s.social_icon} src={tg} alt="Telegram" />
                        </div>
                    ) : null}
                </li>
            )
        })
    }

        const { contacts, subtitle, address, contacts_title, contacts_numbers, contacts_site, contacts_email } = data.contacts
        const { logo } = data.header;
        const contacts_link = data.navigation[5][1]
        return (
            <section className={s.contacts} id="contacts">
                <div className={s.container}>
                    <div className={s.left_block}>
                        <h3 className={s.title}>
                            {t('contacts.contacts')}
                        </h3>
                        <address className={s.address}>
                            <ul className={s.address_list}>
                                {renderList(s.address_item, address)}
                            </ul>
                            <div className={s.contacts_block}>
                                <span className={s.contacts_title}>{t('contacts.contacts_title')}</span>
                                <ul className={s.contacts_list}>
                                    {renderContactList(s.contacts_item, s.contacts_link, contacts_numbers.slice(0, 1), 'tel:', 'social')}
                                    {renderContactList(s.contacts_item, s.contacts_link, contacts_numbers.slice(1), 'tel:')}
                                    {renderContactList(s.contacts_item, s.contacts_link, contacts_site, 'https://')}
                                    {renderContactList(s.contacts_item, s.contacts_link, contacts_email, 'email:')}
                                </ul>
                            </div>
                        </address>
                    </div>
                    <div className={s.right_block}>
                        <Forms className={s.form_size}></Forms>
                        {/* <span className={s.bg_title}>{contacts}</span> */}
                    </div>
                    <div className={s.map}>
                        <MapComponent />
                        {/* <iframe
                            src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac26c6370695b1e6f13f2214b0c15c26073f69cba3feb708f94b3b6566d6b5c72&amp;source=constructor"
                            width="100%"
                            height="100%" title="map"
                        ></iframe> */}
                    </div>

                </div>
            </section>
        )

}

export default Contacts;
