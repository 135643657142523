import React, { Component } from "react";
import stars from './img/stars.jpg';
import s from './styles.module.scss'

export default class Slide extends Component {
    render() {
        const { name, photo, review, auto } = this.props.data
        return (
            <div className={s.slide}>
                <div className={s.slide_author}>
                    <img className={s.slide_img} src={photo} alt={name} />
                    <span className={s.slide_name}>{name} </span>
                </div>
                <p className={s.slide_review}>{review}</p>
                <span className={s.slide_auto}>
                    <img src={stars} alt={name} />
                </span>
            </div>
        )
    }
}