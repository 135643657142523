import React, { Component } from "react";
import { useTranslation } from 'react-i18next';
import data from '../../json/data.json'
import HamburgerMenu from "./hamburger";
import { Menu } from "./menuLang";

// styles
import s from './styles.module.scss'
// images
import geo from './img/geo.png';
import inst from './img/inst.png';
import tg from './img/tg.png';
import vb from './img/vb.png';
import wp from './img/wp.png';

const renderListLi = ($classLi, $classLink, data) => {
    return data.map((arrTextUrl, i) => {
        const [label, url] = arrTextUrl;
        return (
            <li className={$classLi} key={i}>
                <a className={$classLink} href={url}>{label}</a>
            </li>
        )
    })
}

export const Header = (props) => {

    const { t } = useTranslation()

    const renderSocial = () => {
        return (<>
            <li className={s.socials__item}>
                <a href="https://www.instagram.com/wagen.minsk/" target="_blank" rel="noreferrer" className={s.socials__link}>
                    <img src={inst} alt='Instagram' />
                </a>
            </li>
            <li className={s.socials__item}>
                <a href="https://wa.me/375291361777" target="_blank" rel="noreferrer" className={s.socials__link}>
                    <img src={wp} alt='WhatsApp' />
                </a>
            </li>
            <li className={s.socials__item}>
                <a href="viber://chat?number=+375291361777/" className={s.socials__link}>
                    <img src={vb} alt='Viber' />
                </a>
            </li>
            <li className={s.socials__item}>
                <a href="tg://resolve?domain=wagenby/" className={s.socials__link}>
                    <img src={tg} alt='Telegram' />
                </a>
            </li>
        </>)
    }

    // renderListLi = ($classLi, $classLink, data) => {
    //     return data.map((arrTextUrl, i) => {
    //         const [label, url] = arrTextUrl;
    //         return (
    //             <li className={$classLi} key={i}>
    //                 <a className={$classLink} href={url}>{label}</a>
    //             </li>
    //         )
    //     })
    // }


    const { number } = props
    const { logo } = data.header
    const { navigation, contacts: { contacts_email, contacts_numbers } } = data
    return (
        <header className={s.header}>
            <div className={s.container}>
                <a href="/" className={s.logo}>
                    {t('header.logo')}
                </a>
                <div>
                    <nav className={s.menu}>
                        {renderListLi(s.contacts, s.contacts__link, t('navigation', { returnObjects: true }))}
                        {/* <li className={s.contacts}>
                                <a href="">
                                    Наши услуги
                                </a>
                            </li>
                            <li className={s.contacts}>Преимуществa</li>
                            <li className={s.contacts}>Отзывы клиентов</li>
                            <li className={s.contacts}>Наши партнеры</li>
                            <li className={s.contacts}>Купленные авто</li>
                            <li className={s.contacts}>Стоимость услуг</li>
                            <li className={s.contacts}>Контакты</li> */}
                    </nav>
                </div>
                <div className={s.right_header}>
                    <ul className={s.contacts}>
                        {/* <li className={s.contacts__item}>
                                <ul className={s.contacts__socials}>
                                    {this.renderSocial()}
                                </ul>
                            </li> */}
                        <li className={s.contacts__item}>
                            <a href={`tel:+${number.replace(/[^0-9]/g, "")}`} className={s.number}>
                                {number}
                            </a>
                        </li>
                        <li className={s.contacts__item}>
                            <a href="#contacts" className={s.location}>
                                <img src={geo} alt="Contacts" />
                            </a>
                        </li>
                    </ul>
                    <Menu />
                    <HamburgerMenu social={renderSocial()}></HamburgerMenu>
                </div>

            </div>
        </header>
    )
}