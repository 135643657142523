import React, { Component } from "react";
import s from './styles.module.scss'

export default class CardCarItem extends Component {
    render() {
        const { name, img, options, price } = this.props.card;
        const { url = '/' } = this.props;
        return (
            <li className={s.card}>
                <a href={url} className={s.card_link}>
                    <img src={img} alt={name} className={s.card_img} />
                    <div className={s.card_description}>
                        <span className={`${s.card_name} ${s.card_text}`}>{name}</span>
                        <ul className={s.option_list}>
                            {options.map((item, i) => (
                                <li key= {i} className={s.option_item}>
                                    <span className={`${s.option_name} ${s.card_text}`}>{item[0]}:</span>
                                    <span className={`${s.option_value} ${s.card_text}`}>{item[1]}</span>
                                </li>
                            ))}
                        </ul>
                        <span className={`${s.card_price} ${s.card_text}`}>{price}</span>
                    </div>
                </a>
            </li >
        )
    }
}