import './styles/main.scss'
import './styles/media.scss'
import './styles/fonts.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Header } from './components/header/index'
import { MainBlock } from './components/mainBlock';
import { Services } from './components/services';
import { Advantages } from './components/advantages';
import AdvantagesLeasing from './components/advantagesLeasing';
import OurWorks from './components/ourWorks';
import Banner from './components/banner';
import CountingPrice from './components/countingPrice';
import Auctions from './components/auctions';
import Reviews from './components/reviews';
import Consultation from './components/consultation';
import Questions from './components/questions';
import Contacts from './components/contacts';
import Footer from './components/footer';

function App() {
    return (
        <>
            <Header number='+48 733 936 153'></Header>
            <main>
                <MainBlock></MainBlock>
                <Services></Services>
                <Advantages></Advantages>
                {/* <AdvantagesLeasing></AdvantagesLeasing> */}
                <OurWorks></OurWorks>
                {/* <Banner></Banner> */}
                <CountingPrice></CountingPrice>
                <Auctions></Auctions>
                <Reviews></Reviews>
                {/* <Consultation></Consultation>
                <Questions></Questions> */}
                <Contacts></Contacts>
            </main>
            <Footer></Footer>
        </>
    );
}

export default App;
