import React from "react";
import { useTranslation } from 'react-i18next';
import data from '../../json/data.json'
import CardCarItem from "./CardCarItem";
import CarSlider from './CarSlider';
// styles
import s from './styles.module.scss'


const OurWorks = () => {
    const { t } = useTranslation();
    const renderCards =(cards) => {
        return cards.map((card, i) => <CardCarItem key={i} liClass={s.advantages_item} card={card} ></CardCarItem>)
    }


        const { ourWorks } = data.ourWorks;
        const ourWorks_link = data.navigation[2][1]



        return (
            <section className={s.ourWorks} id={ourWorks_link.slice(3)}>
                <div className={s.container}>
                    <h3 className={s.title}>
                        {t('ourWorks.ourWorks').split(' ').slice(0, -1).join(' ') + ' '}
                        <span className={s.bold}>{t('ourWorks.ourWorks').split(' ').at(-1)}</span>
                    </h3>
                    <div style={{ "width": "100%" }}>
                        <CarSlider />
                    </div>
                </div>
            </section>
        )
}

export default OurWorks;
