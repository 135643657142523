import React, { Component } from "react";
import s from './styles.module.scss'

export default class Slide extends Component {
    render() {
        const { name, img, price } = this.props.card;
        const { url = '/' } = this.props;
        return (
            <div className={s.card}>
                <a href={url} className={s.card_link}>
                    <img src={img} alt={name} className={s.card_img} />
                    <div className={s.card_description}>
                        <span className={`${s.card_name} ${s.card_text}`}>{name}</span>
                        <span className={`${s.card_price} ${s.card_text}`}>{price}</span>
                    </div>
                </a>
            </div>
        )
    }
}