import React, { Component } from "react";
import { useState } from "react";
import { Sling as Hamburger, Sling } from 'hamburger-react'
import data from '../../../json/data.json'
// styles
import s from './styles.module.scss'

const HamburgerMenu = (props) => {
    const renderListLi = ($classLi, $classLink, data) => {
        return data.map((arrTextUrl, i) => {
            const [label, url] = arrTextUrl;
            return (
                <li className={$classLi} key={i}>
                    <a className={$classLink} onClick={() => setNavOpen(!navOpen)} href={url}>{label}</a>
                </li>
            )
        })
    }
    const { navigation, contacts: { contacts_email, contacts_numbers } } = data
    const [navOpen, setNavOpen] = useState(false);
    navOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
    return (
        <>
            <div className={s.hamburger} onClick={() => setNavOpen(!navOpen)}>
                <Sling toggle={setNavOpen} toggled={navOpen} color="#FFF" ></Sling>
            </div>
            <div className={`${navOpen ? s.nav_overlay_show : s.nav_overlay}`}>
                <section className={s.navigation}>
                    <ul className={s.nav_list}>
                        {renderListLi(s.nav_item, s.nav_link, navigation)}
                    </ul>
                    <ul className={s.contacts}>
                        {/* <ul className={s.social}>
                            {props.social}
                        </ul> */}
                        <li className={s.contacts_item}>
                            <a className={s.contacts_link}
                                href={`tel:+${contacts_numbers[0].replace(/[^0-9]/g, "")}`}>
                                {contacts_numbers[0]}
                            </a>
                        </li>
                        <li className={s.contacts_item}>
                            <a className={s.contacts_link}
                                href={'mailto:' + contacts_email}>
                                {contacts_email}
                            </a>
                        </li>
                    </ul>
                </section>
            </div>
        </>
    )
}
export default HamburgerMenu