import i18n from 'i18next';
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';

//const getCurrentHost = import.meta.env.MODE === 'development' ? 'http://localhost:3000' : 'https://multi-lang-app-react.netlify.app'

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'pl',
        lng: 'pl',
        interpolation: {
            escapeValue: false
        },
        backend: {
            //loadPath: `http://localhost:3000/i18n/{{lng}}.json`,
            //loadPath: `https://symphonious-pastelito-907336.netlify.app/i18n/{{lng}}.json`,
            // loadPath: `${getCurrentHost}/i18n/{{lng}}.json`,
            loadPath: `https://auctionhousepl.eu/i18n/{{lng}}.json`,
        }
    });

export default i18n;