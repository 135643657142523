import React, { Component } from "react";
import data from '../../json/data.json'
import AdvantagesItem from "./AdvantagesItem";
// styles
import s from './styles.module.scss'

export default class AdvantagesLeasing extends Component {
    renderAdvantagesItems(textArr) {
        return textArr.map((text, i) => <AdvantagesItem key={i} liClass={s.advantages_item} text={text} keyNum={i}></AdvantagesItem>)
    }

    render() {
        const { advantagesLeasing, subtitle, text_items, additional, additional_items } = data.AdvantagesLeasing
        return (
            <section className={s.advantages_leasing}>
                <div className={s.container}>
                    <h3 className={s.title_section}>{advantagesLeasing}</h3>
                    <p className={s.subtitle}>{subtitle}</p>
                    <ul className={s.advantages_list}>
                        {this.renderAdvantagesItems(text_items)}
                    </ul>
                    <h3 className={s.title_section}>{additional}</h3>
                    <ul className={s.additional_advantages_list}>
                        {this.renderAdvantagesItems(additional_items)}
                    </ul>
                </div>
            </section>
        )
    }
}
