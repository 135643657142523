import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

export default function MapComponent() {

    const mapStyles = {
        height: "400px",
        width: "100%",
    };

    const defaultCenter = {
        lat: 53,
        lng: 18.5666,
    };

    const markers = [
        { lat: 53.53336070924171, lng: 17.518715355818795 }, // ul. Głowna 17, 89-430 Kamień Krajeński 53.53336070924171, 17.518715355818795
        { lat: 53.53055896999488, lng: 17.51136869870979 }, // 89-430 Kamień Krajeński Strzelecka 3 53.53055896999488, 17.51136869870979
        { lat: 52.348694797869626, lng: 20.946811950059324 }, // Modlińska 343, 03-170 Warszawa 52.348694797869626, 20.946811950059324
    ];

    return (
        // <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <LoadScript googleMapsApiKey="AIzaSyCN1HrWVUQ3k97p6ikz1EaW5zXglqCSsis">
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={7}
                center={defaultCenter}
            >
                {markers.map((marker) => (
                    <Marker
                        key={marker.id}
                        position={{ lat: marker.lat, lng: marker.lng }}
                    />
                ))}
            </GoogleMap>
        </LoadScript>
    );
}